import * as React from "react";
import { graphql } from "gatsby";
import { Seo } from "../../components/Seo";
import { Header, Footer } from "../../components/layout/index";
import {
  Hero,
  KeyFigures,
  Features,
  Testimonials,
  References,
  BlogListing,
  Cta,
  KeyPoints,
  Partners,
  ContentSection,
} from "../../components/sections/index";
import { urlFor } from "../../utils";

const InteropLgc = props => {
  const { data } = props;

  const page = (data || {}).interopPage;
  const coverPictureURL = (page.hero && urlFor(page.hero.image)) || undefined; // let SEO component define default image if hero has no image

  return (
    <>
      {/* TODO: move to sanity */}
      <Seo
        title="Omnidoc et les logiciels médicaux connectés grâce à de l'interopérabilité"
        description="L'interopérabilité développée entre les logiciels médicaux et Omnidoc permettent aux professionnels de santé de libérer du temps médical et de se concentrer sur leur expertise."
        keywords="téléexpertise médicale rémunérée télé-expertise teleexpertise tele-expertise expertise acte avis télé-dermatologie télédermatologie télé-cardiologie télécardiologie télémédecine télé-médecine assurance maladie interopérabilité Omnidoc logiciels médicaux"
        image={coverPictureURL}
      />
      <Header />
      {page.hero && (
        <Hero
          title={page.hero.title}
          accentTitle={page.hero.accentTitle}
          subtitle="INTEROPÉRABILITÉ LOGICIELS MÉDICAUX" // TODO move to sanity
          description={page.hero.description}
          image={page.hero.image}
          buttons={[
            {
              text: "Demander une démo",
              href: "/demander-une-demo",
            },
          ]}
        />
      )}
      {
        // Force collapsing of the next two sections to appear as one
      }
      {page.partners1 && (
        <Partners
          title={page.partners1.title}
          logoCloudTitle={page.partners1.description}
          partners={page.partners1}
          containerClassName="!pb-0 bg-gray-100"
          useLargeLogos
        />
      )}
      {page.partners2 && (
        <Partners
          title={page.partners2.title}
          logoCloudTitle={page.partners2.description}
          partners={page.partners2}
          containerClassName="!pt-4 bg-gray-100"
          useLargeLogos
        />
      )}
      {page.content && <ContentSection content={page.content} />}
      {page.testimonials && page.testimonials.length > 0 && (
        <Testimonials testimonials={page.testimonials} />
      )}
      <Footer />
    </>
  );
};

export default InteropLgc;

export const query = graphql`
  query InteropLgcPageQuery {
    interopPage: sanityInteropLgcPage(
      _id: { regex: "/(drafts.|)interopLgcPage/" }
    ) {
      hero {
        title
        accentTitle
        description
        image {
          ...Image
          alt
        }
      }
      partners1 {
        title
        description
        logos {
          ...Image
          alt
          url
        }
      }
      partners2 {
        title
        description
        logos {
          ...Image
          alt
          url
        }
      }
      content {
        _rawBody
        title
        description
      }
      testimonials {
        quote
        authorName
        authorTitle
        image {
          ...Image
          alt
        }
      }
    }
  }
`;
